import React, { useContext } from "react";

import {
  numberFormating,
  separateNumbersByThousand,
} from "../ReusableFunctions";
import Instagram from "../../assets/InstagramLogo.svg";
import Lemon8 from "../../assets/Lemon8Logo.svg";
import TikTok from "../../assets/TikTokLogo.svg";
import XiaoHongShu from "../../assets/XiaoHongShuLogo.svg";
import X from "../../assets/XLogo.svg";
import YouTube from "../../assets/YoutubeLogo.svg";

import { Row, Col, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { IconThumbDown, IconThumbUp } from "@tabler/icons-react";

import styles from "./socialDisplay.module.scss";

const tempSocial = {
  Instagram: { socialHandle: "sushi.vid", followerCount: 1400, verified: true },
  YouTube: { socialHandle: "sushi.vid", followerCount: 1230, verified: false },
  TikTok: {
    socialHandle: "sushi.vid",
    followerCount: 13200,
    verified: false,
  },
  X: { socialHandle: "sushi.vid", followerCount: 13200, verified: true },
  XHS: { socialHandle: "sushi.vid", followerCount: 13200, verified: false },
  Lemon8: { socialHandle: "sushi.vid", followerCount: 13200, verified: true },
};

const socialIcons = {
  Instagram: Instagram,
  YouTube: YouTube,
  TikTok: TikTok,
  X: X,
  XHS: XiaoHongShu,
  Lemon8: Lemon8,
};

const {
  Instagram: instagram,
  YouTube: youtube,
  TikTok: tiktok,
  X: x,
  XHS: xhs,
  Lemon8: lemon8,
} = tempSocial;

const socialDetailsType = [
  {
    socials: "Instagram",
    socialHandle: "sushi.vid",
    audienceType: "Followers",
    followerCount: instagram.followerCount,
    engRate: 3.124,
    id: "2",
    verified: instagram.verified,
  },
  {
    socials: "YouTube",
    socialHandle: youtube.socialHandle,
    audienceType: "Subscribers",
    followerCount: youtube.followerCount,
    engRate: 5.224,
    id: "4",
    verified: youtube.verified,
  },
  {
    socials: "X",
    socialHandle: x.socialHandle,
    audienceType: "Followers",
    followerCount: x.followerCount,
    engRate: 5.124,
    id: "1",
    verified: x.verified,
  },
  {
    socials: "XHS",
    socialHandle: xhs.socialHandle,
    audienceType: "Followers",
    followerCount: xhs.followerCount,
    engRate: 3.334,
    id: "3",
    verified: xhs.verified,
  },
];
const ViewSocial = ({
  socials,
  socialHandle,
  audienceType,
  followerCount,
  value,
  engRate,
  verified,
  onClick,
}) => {
  return (
    <React.Fragment key={value}>
      <Col xs={2} md={2} lg={2} className={styles.socialLogoWrapper}>
        <img
          src={socialIcons[socials]}
          alt={socials}
          className={`${styles.socialLogo} `}
        />
      </Col>
      <Col
        xs={10}
        md={10}
        lg={10}
        className={styles.detailWrapper}
        onClick={onClick}
      >
        <h5 className={styles.socialHandle}>{socialHandle}</h5>
        <div className={styles.statWrapper}>
          <div className={`${styles.statContainer} me-2`}>
            <span className={styles.text}>{audienceType}:</span>
            <span className={styles.followerCount}>
              {numberFormating(followerCount)}
            </span>
          </div>
          <div className={styles.statContainer}>
            <span className={styles.text}>Eng Rate:</span>
            <span className={styles.engRate}>
              {separateNumbersByThousand(engRate, {
                roundUp: false,
                decimalPoint: 2,
              })}
              {engRate >= 5 && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip">
                      Engagement Rate is Above Average (Based on 4.21 % from 858
                      same-tier profiles). Last updated 29 Jan 2024 at 6:53 PM
                    </Tooltip>
                  }
                >
                  <IconThumbUp size={16} color="green" />
                </OverlayTrigger>
              )}
            </span>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

const socialDisplay = () => {
  return (
    <>
      {socialDetailsType.map((item, index) => (
        <Col
          xs={12}
          md={6}
          lg={3}
          className={`${styles.socialsDispayWrapperV2} py-1 px-2`}
          key={index}
        >
          <div className={`${styles.movableBlock} px-4 py-3 shadow-sm`}>
            <Row className="justify-content-start ">
              <ViewSocial
                socials={item.socials}
                socialHandle={item.socialHandle}
                audienceType={item.audienceType}
                followerCount={item.followerCount}
                value={item.value}
                verified={item.verified}
                engRate={item.engRate}
              />
            </Row>
          </div>
        </Col>
      ))}
    </>
  );
};

export default socialDisplay;

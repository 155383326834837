import React from "react";
import ViewBio from "./ViewBio";
const tempBio = {
  email: "johnDoe@gmail.com",
  phoneNumber: "011-11112121",
  age: "21",
  race: "Indian",
  maritalStatus: "Single",
  hobbies: ["Jogging"],
  education: "Diploma in Computer Science",
  language: ["English", "Malay", "Chinese"],
};
const {
  email,
  phoneNumber,
  age,
  race,
  maritalStatus,
  hobbies,
  education,
  language,
} = tempBio;

const bioData = [
  {
    caption: "Email",
    value: email,
    typeOfInput: "email",
    placeholder: "sushi@sushivid.com",
    name: "email",
    type: "field",
    id: "1",
    validation: "email",
  },
  {
    caption: "Phone Number",
    value: phoneNumber,
    typeOfInput: "phone",
    placeholder: "011-11112121",
    name: "phoneNumber",
    type: "field",
    id: "2",
  },
  {
    caption: "Age",
    value: age,
    typeOfInput: "number",
    placeholder: "23",
    name: "age",
    type: "field",
    id: "3",
  },
  {
    caption: "Highest Education Level",
    value: education,
    typeOfInput: "text",
    placeholder: "Degree in Computer Science",
    name: "education",
    type: "field",
    id: "4",
  },
  {
    caption: "Marital Status",
    value: maritalStatus,
    typeOfInput: "text",
    placeholder: "Single",
    name: "maritalStatus",
    type: "select",
    option: [
      { label: "Single", value: "Single" },
      { label: "Married", value: "Married" },
    ],
    id: "5",
  },
  {
    caption: "Race",
    value: race,
    typeOfInput: "text",
    placeholder: "Malay",
    name: "race",
    option: [
      { label: "Chinese", value: "Chinese" },
      { label: "Tamil", value: "Tamil" },
      { label: "Malay", value: "Malay" },
    ],
    type: "selectMulti",
    multipleValue: true,
    id: "6",
  },
  {
    caption: "Hobbies",
    value: hobbies,
    typeOfInput: "email",
    placeholder: "Jogging",
    name: "hobbies",
    option: [
      { label: "Jogging", value: "Jogging" },
      { label: "Cycling", value: "Cycling" },
      { label: "Hiking", value: "Hiking" },
    ],
    type: "selectMulti",
    multipleValue: true,
    id: "7",
  },
  {
    caption: "Languages",
    value: language,
    typeOfInput: "text",
    placeholder: "English",
    name: "language",
    option: [
      { label: "English", value: "English" },
      { label: "Chinese", value: "Chinese" },
      { label: "Malay", value: "Malay" },
      { label: "Tamil", value: "Tamil" },
    ],
    type: "selectMulti",
    multipleValue: true,
    id: "8",
  },
];

const BioDetail = () => {
  return (
    <>
      {bioData.map((item, index) => (
        <React.Fragment key={index}>
          <ViewBio
            lg={4}
            md={6}
            xs={12}
            caption={item.caption}
            value={item.value}
            id={index}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default BioDetail;

import React, { useContext, useState } from "react";

import { separateNumbersByThousand } from "../ReusableFunctions";

import styles from "./LastPaid.module.scss";

const LastPaid = () => {
  return (
    <div>
      <h3 className={styles.detail}>
        RM{" "}
        {separateNumbersByThousand(1222, {
          roundUp: false,
          decimalPoint: 2,
        })}
      </h3>
    </div>
  );
};

export default LastPaid;

import React, { useCallback, useEffect, useState, useContext } from "react";

import {
  numberFormating,
  separateNumbersByThousand,
} from "../ReusableFunctions";

import { Card, Row, Col, Button } from "react-bootstrap";
import { IconThumbDown, IconThumbUp } from "@tabler/icons-react";

import styles from "./brandsCollab.module.scss";

import lazada from "../../assets/lazada-logo.png";
import amazon from "../../assets/amazon.png";
import shopee from "../../assets/Shopee.png";

import Instagram from "../../assets/InstagramLogo.svg";
import Lemon8 from "../../assets/Lemon8Logo.svg";
import TikTok from "../../assets/TikTokLogo.svg";
import XiaoHongShu from "../../assets/XiaoHongShuLogo.svg";
import X from "../../assets/XLogo.svg";
import YouTube from "../../assets/YoutubeLogo.svg";

import sponsored1 from "../../assets/sponsored1.png";
import sponsored2 from "../../assets/sponsored2.png";
import sponsored3 from "../../assets/sponsored3.png";

const brandsData = [
  {
    campaignName: "Lazada 1",
    date: "13th December 2023",
    brandImage: lazada,
    brandName: "Lazada",
    likeCount: 22432,
    commentCount: 2000,
    viewCount: 50000,
    engagementRate: 3.422,
    img: sponsored2,
    id: "2",
    social: "Instagram",
    postingLink: "https://www.instagram.com/reel/C5hRdWUyvlI",
    caption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },

  {
    campaignName: "Shopee 2",
    date: "13th December 2023",
    brandImage: shopee,
    brandName: "Shopee",
    likeCount: 22432,
    commentCount: 2000,
    viewCount: 50000,
    engagementRate: 3.433,
    img: sponsored3,
    id: "6",
    social: "TikTok",
    postingLink:
      "https://www.tiktok.com/@zeniafatale/video/7363553682371120400",
    caption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
  {
    campaignName: "Amazon 2",
    date: "13th December 2023",
    brandImage: amazon,
    brandName: "Amazon",
    likeCount: 22432,
    commentCount: 2000,
    viewCount: 50000,
    engagementRate: 3.124,
    img: sponsored1,
    id: "7",
    show: true,
    social: "TikTok",
    postingLink:
      "https://www.tiktok.com/@zeniafatale/video/7363553682371120400",
    caption:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
];

const socialIcons = {
  Instagram: Instagram,
  YouTube: YouTube,
  TikTok: TikTok,
  X: X,
  XHS: XiaoHongShu,
  Lemon8: Lemon8,
};

const ViewBrand = ({
  date,
  brandName,
  img,
  viewCount,
  likeCount,
  commentCount,
  engagementRate,
  social,
  caption,
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className={styles.cardImage}>
        <Card.Img variant="top" src={img} />
        <div className={styles.brandImage}>
          <img src={socialIcons[social]} alt={"brandName"} />
        </div>
      </div>
      <Card.Body className="pb-1">
        <Row className="pb-2">
          <div className={styles.captionBox}>
            {showMore ? caption : `${caption.substring(0, 50)}`}...
            <button
              type="button"
              onClick={() => setShowMore(!showMore)}
              className={styles.viewMoreButton}
            >
              {showMore ? "View Less" : "View More"}
            </button>
          </div>
        </Row>
        <Row className="d-flex">
          <Col className={styles.statCounter}>
            <div className={styles.iconTextContainer}>
              <h5 className={styles.text}>Likes</h5>
            </div>
            <h5 className={styles.stat}>{numberFormating(likeCount)}</h5>
          </Col>
          <Col className={styles.statCounter}>
            <div className={styles.iconTextContainer}>
              <h5 className={styles.text}>Comments</h5>
            </div>
            <h5 className={styles.stat}>{numberFormating(commentCount)}</h5>
          </Col>
          <Col className={styles.statCounter}>
            <div className={styles.iconTextContainer}>
              <h5 className={styles.text}>Views</h5>
            </div>
            <h5 className={styles.stat}>{numberFormating(viewCount)}</h5>
          </Col>
          <Col className={styles.statCounter}>
            <div className={styles.iconTextContainer}>
              <h5 className={styles.text}>Eng Rate</h5>
            </div>
            <h5 className={styles.stat}>
              {separateNumbersByThousand(engagementRate, {
                roundUp: false,
                decimalPoint: 2,
              })}
            </h5>
          </Col>
        </Row>
        <div className={styles.collabBrands}>
          <p className={styles.text}>
            {brandName} Collab on {date}
          </p>
        </div>
      </Card.Body>
    </>
  );
};

const BrandsCollab = () => {
  return (
    <>
      {brandsData.map((item, index) => (
        <Col lg={4} xs={12} md={6} className={styles.brandWrapper} key={index}>
          <Card className="p-3 shadow-sm">
            <ViewBrand
              date={item.date}
              brandName={item.brandName}
              img={item.img}
              viewCount={item.viewCount}
              engagementRate={item.engagementRate}
              social={item.social}
              caption={item.caption}
              likeCount={item.likeCount}
              commentCount={item.commentCount}
            />
          </Card>
        </Col>
      ))}
    </>
  );
};

export default BrandsCollab;

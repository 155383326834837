import React from "react";

import { separateNumbersByThousand } from "../ReusableFunctions";

import { IconThumbUp, IconThumbDown } from "@tabler/icons-react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";

import styles from "./RatesBlockBasic.module.scss";

const rate = [
  {
    caption: "IG Reels",
    rate: 230,
    priceMax: 430,
    rateType: "iGReels",
    id: "4",
  },
  {
    caption: "YouTube",
    rate: 230,
    priceMax: 430,
    rateType: "youtuber",
    id: "6",
  },
  {
    caption: "TikTok",
    rate: 2600,
    priceMax: 2800,
    rateType: "tiktok",
    id: "1",
  },
  {
    caption: "Event Attendance",
    rate: 230,
    priceMax: 430,
    rateType: "eventAttendance",
    id: "5",
  },
];

const ViewRates = ({ caption, rate, priceMax }) => {
  return (
    <Col xs={12} lg={12} className={styles.rateBlockContainer}>
      <Row>
        <Col lg={12} xs={5}>
          <h5 className={styles.caption}>{caption}</h5>
        </Col>
        <Col lg={12} xs={7}>
          <span className={styles.priceRange}>
            <span className={styles.formSub}>RM</span>
            <span className="pe-1">
              {separateNumbersByThousand(rate, {
                roundUp: false,
                decimalPoint: 0,
              })}
            </span>
            -
            <span className="ps-1">
              {separateNumbersByThousand(priceMax, {
                roundUp: false,
                decimalPoint: 0,
              })}
            </span>
            {rate <= 250 && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    Rate is lower than Average (Based on 4.21 % from 858
                    same-tier profiles). Last updated 29 Jan 2024 at 6:53 PM
                  </Tooltip>
                }
              >
                <IconThumbUp size={16} color="green" />
              </OverlayTrigger>
            )}
          </span>
        </Col>
      </Row>
    </Col>
  );
};

const Rates = () => {
  return (
    <>
      {rate.map((item, index) => (
        <Col lg={3} md={6} xs={12} className="p-2 " key={index}>
          <div className={`${styles.movableBlock} px-2 py-2 shadow-sm`}>
            <ViewRates
              caption={item.caption}
              rate={item.rate}
              priceMax={item.priceMax}
            />
          </div>
        </Col>
      ))}
    </>
  );
};
export default Rates;

import React from "react";

import styles from "./BioDetail.module.scss";

import { Col } from "react-bootstrap";

const ViewBio = ({ lg, md, xs, id, caption, value, onClick }) => {
  return (
    <>
      <Col lg={lg || 12} md={md || 12} xs={xs || 12} key={id} onClick={onClick}>
        <h5 className={styles.caption}>{caption}</h5>
        <h3 className={styles.detail}>
          {Array.isArray(value) ? value.join(", ") : value}{" "}
        </h3>
      </Col>
    </>
  );
};

export default ViewBio;

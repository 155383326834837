import React from "react";

import { Row, Col } from "react-bootstrap";
import MediaQuery from "react-responsive";

import styles from "./ReviewBlock.module.scss";

const tempReview = {
  contentCreativity: 4.5,
  contentQuality: 4.5,
  responsiveness: 5,
};

const { contentCreativity, contentQuality, responsiveness } = tempReview;

const ratingType = [
  { caption: "Content Creativity", value: contentCreativity },
  { caption: "Content Quality", value: contentQuality },
  { caption: "Responsiveness", value: responsiveness },
];

const reviewType = [
  {
    author: "Jane",
    reviewDate: "13th December 2023",
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    author: "Jane",
    reviewDate: "13th December 2023",
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  },
];

const ReviewBlock = () => {
  return (
    <>
      <Row className="d-flex mt-3">
        {ratingType.map((data, index) => (
          <Col xs={12} lg={4} className={styles.reviewTypeWrapper} key={index}>
            <h5 className={styles.caption}>{data.caption}</h5>
            <span className={styles.value}>{data.value}</span>
          </Col>
        ))}
        <div className={styles.dividerWrapper}>
          <hr className={styles.horizantalDividerForBLocks} />
        </div>
      </Row>
      <Row className={styles.reviewWrapper}>
        {reviewType.map((data, index, reviewsType) => (
          <React.Fragment key={index}>
            <Col xs={12} lg={5}>
              <Row className="justify-content-between">
                <Col xs={7} lg={6} className={styles.authorWrapper}>
                  <h5 className={styles.caption}>{data.author}</h5>
                  <span className={styles.muted}>from SushiVid</span>
                </Col>
                <Col xs={5} lg={5} className={styles.authorDateWrapper}>
                  <span className={styles.mutedDate}>{data.reviewDate}</span>
                </Col>
                <p className={styles.reviewText}>{data.reviewText}</p>
              </Row>
            </Col>

            {index !== reviewType.length - 1 && (
              <>
                <MediaQuery maxWidth={992}>
                  <div className={styles.dividerWrapper}>
                    <hr className={styles.horizantalDivider} />
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={992}>
                  <Col lg={1} className={styles.verticalDividerWrapper}>
                    <div
                      className="vr"
                      style={{
                        padding: "0rem .1rem",
                        height: "3rem",
                        borderRadius: "15px",
                        alignSelf: "center",
                      }}
                    />
                  </Col>
                </MediaQuery>
              </>
            )}
          </React.Fragment>
        ))}
      </Row>
    </>
  );
};

export default ReviewBlock;

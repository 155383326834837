import styles from "./SectionTitle.module.scss";

const SectionTitle = ({ title }) => {
  return (
    <>
      <h2 className={styles.sectionTitle}>{title}</h2>
    </>
  );
};

export default SectionTitle;

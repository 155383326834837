export const separateNumbersByThousand = (
  number,
  { roundUp = false, decimalPoint = 2, defaultValue = "" } = {}
) => {
  if (typeof number === "string") return number;
  if (isNaN(number) || number === undefined || number === null)
    return defaultValue;

  const finalNumber = roundUp ? Math.ceil(number) : number;

  try {
    return finalNumber.toLocaleString(undefined, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });
  } catch (e) {
    return "";
  }
};

export const numberFormating = (number) => {
  if (number < 1e3) return number;
  // 1e3 = 1 * 10^3
  // divide with 1000
  // + unary operator is to remove any trailing zero eg 15.00 the decimal points
  //.toFixed(1), rounds to 1 decimal points

  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + "k";
  // if number is between 1,000 to 999,999

  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + "M";
  // if number is between 1,000,000 to 999,999,999

  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + "B";
  // if number is between 1,000,000,000 to 999,999,999,999
};

import React from "react";

import MediaQuery from "react-responsive";

import styles from "./AvatarContainer.module.scss";
import avatar from "../../assets/avatarPicture.png";

const ViewAvatar = () => {
  return (
    <>
      <MediaQuery maxWidth={992}>
        <div
          className={styles.profilePictureBasicMobileWrapper}
          style={{ backgroundImage: `url(${avatar})` }}
        ></div>
      </MediaQuery>
      <MediaQuery minWidth={993}>
        <div
          className={styles.profilePictureBasicDesktop}
          style={{ backgroundImage: `url(${avatar})` }}
        ></div>
      </MediaQuery>
    </>
  );
};

export default ViewAvatar;

import React from "react";
import styles from "./CoverPictureBasic.module.scss";
import cover from "../../assets/coverPicture.png";
import MediaQuery from "react-responsive";
import ViewAvatar from "../avatarContainer/ViewAvatar";

const ViewCoverPicture = () => {
  return (
    <div>
      <div
        className={styles.coverPictureBasic}
        style={{
          backgroundImage: `url(${cover})`,
        }}
      ></div>
      <MediaQuery maxWidth={992}>
        <div className={styles.avatarWrapper}>
          <ViewAvatar />
        </div>
      </MediaQuery>
    </div>
  );
};

export default ViewCoverPicture;

import "./App.scss";

import { Button, Container, Row, Col } from "react-bootstrap";
import ViewCoverPicture from "./components/coverPictures/ViewCoverPictures";
import ViewHeroName from "./components/heroName/ViewHeroName";
import ViewAvatar from "./components/avatarContainer/ViewAvatar";
import ViewCategory from "./components/categoryDisplay/ViewCategory";
import BioDetail from "./components/bioDetail/BioDetail";
import ReviewBlock from "./components/reviews/ReviewBlock";
import SectionTitle from "./components/sectionTitle/SectionTitle";
import BrandsCollab from "./components/brandsCollab/brandsCollab";
import SocialDisplay from "./components/socialDisplay/socialDisplay";
import Rates from "./components/rates/ViewRates";
import LastPaid from "./components/LastPaid/LastPaid";

import MediaQuery from "react-responsive";

const App = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate = new Date();

  return (
    <div className="App">
      <Container fluid className="m-0 pb-5 px-0">
        <ViewCoverPicture />
        <Container>
          <Row className="pt-3">
            <Col lg={7}>
              <ViewHeroName />
              <ViewCategory />
            </Col>
            <MediaQuery minWidth={992}>
              <Col
                xs={12}
                lg={5}
                className="d-flex justify-content-center align-items-center"
              >
                <ViewAvatar />
              </Col>
            </MediaQuery>
          </Row>
          <hr className="mt-2 mb-3" />
          <Row>
            <BioDetail />
          </Row>
          <hr className="mt-2 mb-3" />
          <Row>
            <SectionTitle title="Reviews" />
            <ReviewBlock />
          </Row>
          <hr className="mt-2 mb-3" />
          <Row>
            <SectionTitle title="Brands I have worked with" />
            <BrandsCollab />
          </Row>
          <hr className="mt-2 mb-3" />
          <Row>
            <SectionTitle title="My socials" />
            <SocialDisplay />
          </Row>
          <hr className="mt-2 mb-3" />
          <Row className="pb-1">
            <SectionTitle title="Rate card" />
            <Rates />
          </Row>
          <hr className="mt-2 mb-3" />
          <Row>
            <SectionTitle
              title={`Last paid amount by SushiVid  ${currentDate.getDate()} ${
                months[currentDate.getMonth()]
              } ${currentDate.getFullYear()}`}
            />
            <LastPaid />
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default App;

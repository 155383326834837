import React from "react";

import ViewBio from "../bioDetail/ViewBio";

const ViewCategory = () => {
  const categoryItem = [
    {
      caption: "Category",
      value: ["LifeStyle", "Beauty", "Fitness"],
      typeOfInput: "text",
      placeholder: "LifeStyle",
      name: "category",
      type: "selectMulti",
      option: [
        { label: "LifeStyle", value: "LifeStyle" },
        { label: "Beauty", value: "Beauty" },
        { label: "Fitness", value: "Fitness" },
      ],
      multipleValue: true,
      id: "1",
    },
    {
      caption: "About Me",
      value:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
      typeOfInput: "text",
      placeholder:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod",
      name: "aboutMe",
      type: "field",
      id: "2",
    },
  ];

  return (
    <>
      {categoryItem.map((item, index) => (
        <React.Fragment key={index}>
          <ViewBio
            lg={12}
            md={12}
            xs={12}
            caption={item.caption}
            value={item.value}
            id={index}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default ViewCategory;
